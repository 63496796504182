import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
    ) { }

    signUp(request): Observable<any> {
        return this.http.post(`/dev/auth/signUp`, request)
    }

}