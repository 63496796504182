import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/auth.service';
import { UserLoginService } from '../../shared/services/cognito/user-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  success: boolean;
  errorMsg;
  alreadyLoggedIn: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserLoginService,
  ) { }

  ngOnInit() {
    this.userService.isAuthenticated((message, result) => {
      this.isLoggedIn(message, result);
    });
  }

  submit() {
    if (this.username && this.password) {
      this.userService.authenticate({ username: this.username, password: this.password }, (message, isLoggedIn) => {
        this.cognitoCallback(message, isLoggedIn);
      });
    }
  }

  cognitoCallback(message: string, result: any) {
    console.log(message);
    if (message != null) {
      switch (message) {
        case 'User does not exist.': {
          this.errorMsg = 'User does not exist';
          break;
        }
        case 'Incorrect username or password.': {
          this.errorMsg = 'Incorrect username or password';
          break;
        }
        default: {
          this.username = '';
          this.password = '';
          break;
        }

      }
    }
    else {
      this.isLoggedIn(message, result);
    }
  }

  isLoggedIn(message: string, isloggedIn: boolean) {
    if (isloggedIn) {
      this.userService.getParameters(data => {
        console.log(data);
        this.alreadyLoggedIn = true;
        this.router.navigate(['/app/dashboard']);
      })
    }
  }
}
