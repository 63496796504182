import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../../../shared/services/cognito/user-login';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

    constructor(
        private userLoginService: UserLoginService
    ) { }

    ngOnInit() {
        this.userLoginService.getParameters(data => {
            console.log(data);
        })
    }

    logout() {
        this.userLoginService.logout();
    }

}
