import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../shared/services/cognito/user-login';
import { Router } from '@angular/router';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit {
  private alreadyLoggedIn: boolean;

  constructor(
    private router: Router,
    private userService: UserLoginService
  ) { }

  ngOnInit() {
    this.userService.isAuthenticated((message, result) => {
      this.isLoggedIn(message, result);
    });
  }

  isLoggedIn(message: string, isloggedIn: boolean) {
    if (isloggedIn) {
      this.alreadyLoggedIn = true;
    } else {
      // this.router.navigate(['/login']);
    }
  }

}
