import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { CoreCompComponent } from './core-comp/core-comp.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TruncateModule } from 'ng2-truncate';


import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {SingleMediaPlayer} from './single-media-player';

@NgModule({
  imports: [
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CommonModule,
    DashboardRoutingModule,
    NgxPaginationModule,
    TruncateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CoreCompComponent,
  ]
})
export class DashboardModule { }
