import { Component, OnInit } from '@angular/core';
import { MediaService } from '../../../shared/services/media.service';
import { HttpService } from '../../../../app/shared/services/http.service';
import { Http, ResponseContentType } from '@angular/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import 'rxjs/add/operator/toPromise';


@Component({
  selector: 'app-core-comp',
  templateUrl: './core-comp.component.html',
  styleUrls: ['./core-comp.component.scss']
})
export class CoreCompComponent implements OnInit {

  nameFilter;
  typeFilter;
  emailFilter;
  contextFilter;

  fileObjects = [];
  filteredFiles = [];
  myImgUrl: 'https://www.iconsdb.com/icons/preview/gray/video-play-xxl.png';
  imageToShow: any;
  selectedImagesArray = [];
  image = [];
  public activeStatus = true;
  collection = [];
  selectedItem;

  p;
  total;

  constructor(
    private mediaService: MediaService,
  ) { }

  selectItem(item) {
    this.selectedItem = item;
    this.selectedItem.image = this.selectedItem.url.replace('%2F', '/')
    this.selectedItem.image = this.selectedItem.image.replace('%40', '@')
    this.selectedItem;
    this.selectedItem.location = this.selectedItem.image.split('/').reverse()[1] + '/' + this.selectedItem.image.split('/').reverse()[0];
    console.log(this.selectedItem);
    // this.activeStatus = !this.activeStatus;
  }

  resetFilter() {
    this.filteredFiles = this.fileObjects;
  }

  ngOnInit() {
    console.log('core cmp works!');
    this.mediaService.getAllContent()
      .subscribe(res => {
        console.log(res);

        this.fileObjects = res.allContent.sort(function (a, b) {
          return a.timestamp < b.timestamp;
        });;
        this.filteredFiles = [...this.fileObjects];

        console.log(this.fileObjects);

      })
  }

  filter(type) {
    console.log(
      this.nameFilter,
      this.typeFilter,
      this.emailFilter,
      this.contextFilter,
      type
    )
    this.filteredFiles = this.fileObjects.filter(
      elem => {
        if (type == 'name') {
          return elem.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
        } else if (type == 'type') {
          return elem.fileType.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1
        } else if (type == 'context') {
          console.log(elem);
          return elem.context.toLowerCase().indexOf(this.contextFilter.toLowerCase()) > -1
        } else {
          return elem.user.email.toLowerCase().indexOf(this.emailFilter.toLowerCase()) > -1
        }
      });
  }

  clearSearch() {
    console.log(
      this.nameFilter,
      this.typeFilter,
      this.emailFilter,
      this.contextFilter
    )
    this.nameFilter = undefined;
    this.typeFilter = undefined;
    this.emailFilter = undefined;
    this.contextFilter = undefined;

    this.resetFilter();
  }
}
