import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class MediaService {

    constructor(
        private httpService: HttpService
    ) { }

    getAllContent(): any {
        return this.httpService.makeGet('/dev/media/getAllContent')
    }

}
