import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  username: string;
  password: string;


  constructor(
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
  }

  submit() {
    if (this.username && this.password) {
      console.log(this.username,this.password);
      this.authService.signUp({ username: this.username, password: this.password }).subscribe((response)=>{
        console.log(response,"00000000000000")
      })
      
    }
  }

}
