import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SecureRoutingModule } from './secure-routing.module';

import { SecureComponent } from './secure.component';

import { NavBarModule } from './shared/nav-bar/nav-bar.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserLoginService } from '../shared/services/cognito/user-login';
import { UtilityService } from '../shared/services/cognito/utility.service';
import { AuthenticationService } from '../shared/services/auth.service';
import { MediaService } from '../shared/services/media.service';

@NgModule({
  imports: [
    CommonModule,
    SecureRoutingModule,
    NavBarModule,
    DashboardModule,
    FormsModule
  ],
  declarations: [
    SecureComponent
  ],
  providers: [
    UserLoginService,
    UtilityService,
    AuthenticationService,
    MediaService
  ]
})
export class SecureModule { }
