import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Http, URLSearchParams, Headers } from '@angular/http'
import 'rxjs/Rx';
import { CognitoUtilService } from './cognito/cognito-util';

@Injectable()
export class HttpService {
    token;

    constructor(
        private http: HttpClient,
        private cognitoUtilService: CognitoUtilService,
    ) {
        this.cognitoUtilService.getToken((token) => {
            this.token = token;
        });
    }

    makeGet(baseURL, params?) {
        let URL = `${baseURL}`;

        if (params) {
            const queryParams = this.jsonToQueryParams(params);
            URL = `${baseURL}?${queryParams}`;
        }

        let header = new HttpHeaders();
        header.append('Access-Control-Allow-Origin', '*');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', this.token);
        // let options = new RequestOptions({ headers: header });

        return this.http.get(URL, { headers: header });
    }

    makePost(baseURL, params) {

        let header = new HttpHeaders();
        header.append('Access-Control-Allow-Origin', '*');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', this.token);
        // let options = new RequestOptions({ headers: header });

        return this.http.post(`${baseURL}`, params, { headers: header });
    }

    makePostUnauth(baseURL, params) {
        return this.http.post(`${baseURL}`, params);
    }

    makeGetUnauth(baseURL, params?) {
        let URL = `${baseURL}`;

        if (params) {
            const queryParams = this.jsonToQueryParams(params);
            URL = `${baseURL}?${queryParams}`;
        }

        return this.http.get(URL);
    }

    jsonToQueryParams(params) {
        let queryParams = new URLSearchParams();
        for (let key in params) {
            params.set(key, params[key]);
        }
        return queryParams;
    }

    downLoadFile(baseURL,fileName){
        let URL = `${baseURL}`;

        console.log(URL , fileName);

        return this.http.get(URL)
        .map((response) => {
           let blob = new Blob([response], { type: 'image/jpg' });
           let url= window.URL.createObjectURL(blob);
           window.open(url);
      });
        
    }
}
