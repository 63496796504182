import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { PublicModule } from './public/public.module';
import { SecureModule } from './secure/secure.module';

import { AppComponent } from './app.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { MediaService } from './shared/services/media.service';
import { AuthenticationService } from './shared/services/auth.service';
import { UtilityService } from './shared/services/cognito/utility.service';
import { HttpService } from './shared/services/http.service';
import { CognitoUtilService } from './shared/services/cognito/cognito-util';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PublicModule,
    SecureModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    MediaService,
    AuthenticationService,
    UtilityService,
    HttpService,
    CognitoUtilService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
